interface MenuItem {
  name: string;
  key: string;
  url: string;
  loggedIn?: boolean;
}

interface Menus {
  header: {
    center: MenuItem[];
    right: MenuItem[];
  };
  footer: {
    center: MenuItem[];
    right: MenuItem[];
  };
}

export const site = {
  shortName: 'Insomnia',
  name: 'Insomnia',
  description: 'Leading Open Source API Client, and Collaborative API Design Platform for GraphQL, and REST',
  copyright: 'Kong Inc.',
  copyrightURL: 'https://konghq.com',
};

export const publicUrls = {
  privacy: 'https://insomnia.rest/privacy/',
  terms: 'https://insomnia.rest/terms/',
};

export const menus: Menus = {
  header: {
    center: [],
    right: [
      {
        name: 'Account',
        key: 'account',
        url: '/app/account/',
        loggedIn: true,
      },
      {
        name: 'Login',
        key: 'login',
        url: '/app/login/',
        loggedIn: false,
      },
    ],
  },
  footer: {
    center: [
      {
        name: 'Support',
        key: 'support',
        url: 'https://insomnia.rest/support/',
      },
      {
        name: 'Slack',
        key: 'slack',
        url: 'https://chat.insomnia.rest',
      },
      {
        name: 'GitHub',
        key: 'github',
        url: 'https://github.com/Kong/insomnia',
      },
      {
        name: 'Twitter',
        key: 'twitter',
        url: 'https://twitter.com/GetInsomnia',
      },
      {
        name: 'Blog',
        key: 'blog',
        url: 'https://konghq.com/blog/tag/insomnia',
      },
    ],
    right: [
      {
        name: 'Terms',
        key: 'terms',
        url: publicUrls.terms,
      },
      {
        name: 'Privacy',
        key: 'privacy',
        url: publicUrls.privacy,
      },
    ],
  },
};

interface Platforms {
  mac: Platform;
  windows: Platform;
  linux: Platform;
}

interface Platform {
  platform: string;
  url: string;
}

export const platforms: Platforms = {
  mac: {
    platform: 'MacOS',
    url: 'https://updates.insomnia.rest/downloads/mac/latest?app=com.insomnia.app&source=website',
  },
  windows: {
    platform: 'Windows',
    url: 'https://updates.insomnia.rest/downloads/windows/latest?app=com.insomnia.app&source=website',
  },
  linux: {
    platform: 'Linux',
    url: 'https://updates.insomnia.rest/downloads/ubuntu/latest?app=com.insomnia.app&source=website',
  },
};

export const allReleasesUrl: string =
  'https://updates.insomnia.rest/downloads/release/latest?app=com.insomnia.app&source=website';
